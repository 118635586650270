import { navigate } from "gatsby";
import React from "react";
import loadable from "@loadable/component";
import { MAIN_BUTTON } from "@constants";
import {
    Title,
    Description,
    ContentContainer,
    ButtonContainer,
    MainContainer,
    BackgroundBlur,
} from "./PageHeader.style";

const Button = loadable(() => import("@common/Button/Button"));

const PageHeader = ({
    headerTitle,
    headerDescription,
    headerButtonText,
    headerButtonNavigation,
    headerMainImage,
}) => (
    <MainContainer imageUrl={headerMainImage}>
        <BackgroundBlur />
        <ContentContainer>
            <Title>{headerTitle}</Title>
            <Description>{headerDescription}</Description>
            <ButtonContainer>
                <Button
                    text={headerButtonText}
                    type={MAIN_BUTTON}
                    handleClick={() =>
                        navigate(headerButtonNavigation ?? "/contact-us")
                    }
                />
            </ButtonContainer>
        </ContentContainer>
    </MainContainer>
);

export default PageHeader;
