import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const Title = styled.h1`
    font-size: 3rem;
    line-height: 4.8125rem;
    color: white;
    margin: 0 0 2.5rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2.5rem;
        line-height: 3.5rem;
        margin-bottom: 1.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
`;

export const Description = styled.p`
    font-weight: 200;
    line-height: 1.5rem;
    color: var(--main-font-color);
    max-width: 48rem;
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        width: 100%;
    }
`;

export const ContentContainer = styled.div`
    margin-bottom: 2.5rem;
    width: 50%;
    height: calc(100vh - 6.5rem);
    display: flex;
    min-height: 37.5rem;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 43.75rem;
    max-height: 48.3125rem;
    padding: 0 16rem;

    p {
        margin: 0 0 2.5rem;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 100%;
        height: auto;
        max-width: none;
        padding: 0 8rem;

        p {
            margin-bottom: 1.5rem;
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        min-height: 18.75rem;
        padding: 0 1.5rem;
    }
`;

export const MainContainer = styled.div`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    height: 50rem;
    overflow: hidden;
    z-index: -10;
    background: url(${props => props.imageUrl});
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
  
  @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
    height: 29.375rem;  
    align-items: flex-end;
    margin-top: -5.5rem;
  }
  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    height: 35rem;
    margin-top: -3.5rem;
  }
`;
export const ButtonContainer = styled.div`
    max-width: 15.3125rem;
    white-space: nowrap;
    .button {
        justify-content: center;
    }
    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        max-width: 100%;
    }
`;

export const BackgroundBlur = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0deg, #2b0934 0%, rgba(43, 9, 52, 0.4) 100%);
    -webkit-transform: translate3d(0, 0, 0);
    z-index: -5;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 100vw;
        background: linear-gradient(
            0deg,
            #2b0934 2%,
            rgba(43, 9, 52, 0.4) 100%
        );
    }
`;
